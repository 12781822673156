html {
  margin-top: 0 !important;
}

* html body {
  margin-top: 0 !important;
}

//SET THE HEADER ICON PROPERTIES
$header-icon-background: '../img/logo.png';
$header-icon-width: 50px;
$header-icon-height: 50px;
$header-icon-margin: 16px 32px;



.top-bar {
  @include breakpoint(large) {
    padding: 0;
  }

  background-color: transparent;

  ul {
    background-color: transparent;

    li {
      &.current-page-ancestor {
        .submenu {
          li {
            &.current-menu-item {
              a {
                color: $black;
              }
            }

            a {
              color: $black;

              &:hover {
                color: dodgerblue;
              }
            }
          }
        }

        a {
          color: $black;
        }
      }

      &.current-menu-item {
        a {
          color: $black;
        }

        ul.submenu {
          li {
            a {
              color: $black;
            }
          }
        }
      }

      a {
        color: $black;
        font-weight: 400;

        &:hover {
          color: blue;
        }

        &:active {
          color: blue;
        }
      }
    }
  }
}

li.is-dropdown-submenu-parent {
  &:hover {
    .js-dropdown-active {
      display: block;
    }
  }

  .is-dropdown-submenu {
    display: none;
    position: absolute;
  }
}

body.panel-open {
  #mobile-menu {
    display: block;
  }
}

#nav-icon {
  width: 30px;
  height: 30px;
  right: 20px;
  top: 20px;
  position: absolute;
  display: block;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #fff;
  border-radius: 2px;
  opacity: 1;
  right: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 8px;
}

#nav-icon span:nth-child(4) {
  top: 16px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 5px;
  width: 0%;
  left: 50%;
}

// Title bar

.title-bar {
  background-color: white;
  padding: 0;
}