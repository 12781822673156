/**
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */

 // For this snippet to work, use markup ...
 // <body>
 //   <header>…</header>
 //   <main class="Site-content">…</main>
 //   <footer>…</footer>
 // </body>

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%; /* 1, 3 */
}

.site-header,
.site-footer {
  flex: none; /* 2 */
}

.site-content {
  flex: 1 0 auto; /* 2 */
  padding: var(--space) var(--space) 0;
  width: 100%;
}
.site-content::after {
  content: '\00a0'; /* &nbsp; */
  display: block;
  margin-top: var(--space);
  height: 0px;
  visibility: hidden;
}
@media (--break-lg) {
  .site-content {
    padding-top: var(--space-lg);
  }
  .site-content::after {
    margin-top: var(--space-lg);
  }
}

.site-content--full {
  padding: 0;
}
.site-content--full::after {
  content: none;
}