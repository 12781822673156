

ul {

	&.list-style-plus {

		margin: 0;
		font-size: rem-calc(28);

		li {

			position: relative;
			padding: 22px 52px 26px 67px;
			list-style: none;
			line-height: rem-calc(32);

			&:before {
				display: block;
				content: ' ';
				background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjYxODYgMTczOCAzNSAzNSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogIzM2MzYzNjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9Ikdyb3VwXzM1IiBkYXRhLW5hbWU9Ikdyb3VwIDM1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MzU1IDIzOSkiPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV83MSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNzEiIGNsYXNzPSJjbHMtMSIgd2lkdGg9IjkiIGhlaWdodD0iMzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDg0NCAxNDk5KSIvPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV83MiIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNzIiIGNsYXNzPSJjbHMtMSIgd2lkdGg9IjkiIGhlaWdodD0iMzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDgzMSAxNTIxKSByb3RhdGUoLTkwKSIvPgogIDwvZz4KPC9zdmc+Cg==');
				font-size: 44px;
				width: 36px;
				height: 36px;
				@include xy-center();
				position:absolute;
				left: 0;
				margin-top: -3px;
			}
			
			border-bottom: 1px solid #9A9797;

			&:last-child {
				border: none;
			}
		}
	}


}