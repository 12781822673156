/**
 * Buttons and links
 */

a {
	text-underline-position: under;
}

button, .button { 
	
	&:focus, 
	&:hover, 
	&:active {
	}

	text-transform: uppercase;
	font-size: rem-calc(18);
	border: none;

	&.gform_button {
		display: none;
	}
	
	&.button-arrow-left, 
	&.button-arrow-right {
		
		position: relative;

		&:after {
			position: absolute;
			top:44%;
			-webkit-transform: translateY(-44%);
			-ms-transform: translateY(-44%);
			transform: translateY(-44%);
			font-family: Arial, Helvetica, sans-serif;
			display: inline-block;
						
		}

		&.button-arrow-left {
			padding: rem-calc(16) rem-calc(16) rem-calc(16) rem-calc(30);
			&:after {
				left: 0;
				content: "\2190";
				transition: left 0.3s;
			}

			&:hover {
				&:after {
					left: -5px;
				}
			}
		}

		&.button-arrow-right {
			padding: rem-calc(16) rem-calc(30) rem-calc(16) rem-calc(16);
			&:after {
				right: 0;
				content: "\2192";
				transition: right 0.3s;
			}

			&:hover {
				&:after {
					right: -5px;
				}
			}
		} 

		&.button-submit-1 {
			
			padding: rem-calc(16) rem-calc(46) rem-calc(16) rem-calc(16);
			
			&:after {
				right: 16px;
			}

			&:hover {
				&:after {
					right: 11px;
				}
			}
		}
	}
}

.bgc {
	&-black {
		button, .button { 			
			color: $white;
			&:hover {
				background-color: #383838;
			}			
		}
	}
}

