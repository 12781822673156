[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  border: none;
  font-family: 'Montseratt', sans-serif;
  background: #fff;
  color: #222;

  &:focus {
    background: #ffffff;
    color: #222;
    border: 1px solid #18305b;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $font-gray;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $font-gray;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $font-gray;
  }
}

.form {
  ul {
    padding: 0;
    margin: 0;

    li {
      position: relative;
      list-style: none;
      padding: 0;
      margin: 0;

      label {
        display: none;
      }
    }
  }

  .validation_message {
    margin-bottom: rem-calc(20);
  }

  .gform_confirmation_wrapper {
    text-align: center;
  }
}
