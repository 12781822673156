.cc-window {
	z-index: 999999;
}

.cc-window, 
.cc-banner, 
.cc-type-info {	
	
	.inny, 
	.cc-compliance { 

		padding: 0 16px 0;		

		//@include breakpoint(medium) {
		//	padding: 0;
		//}
	}

	.inny {
		width: 100%;
		max-width: 62.5rem; 
		text-align: center;
		margin: 0 auto;
	}

	.cc-message {
		font-size: rem-calc(16);
		font-family: rooklynSamuelsFiveLight, Arial, sans-serif;
		line-height: 1.5rem;
		a {
			text-decoration: underline;
			white-space: nowrap;

			&:hover {
				color: #00d8ff;
			}
		}
		float: left;
		width: 80%;
		text-align: left;
	}

	.cc-compliance {		
		float: right;
	}

	.cc-btn, .cc-dismiss {	
		border-radius: 3px !important;
		font-size: 1rem;
		width: auto;
		height: auto;
		letter-spacing: 0.1rem;
		text-align: center;
		border-radius: 3px;
		line-height: 1.557;
		padding: .5em 1.5em !important;
		font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !important;
		border: solid 1px #00d8ff !important;
		color: #000 !important;
		opacity: 0.9;
		
		&:hover {
			color: #000 !important;
			border: solid 1px #00d8ff !important;
			background: #00d8ff !important;
			opacity: 1;
		}
	}
}

