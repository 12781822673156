/**
 * FOOTER 
 */
.site-footer {	
	border-top: 1px solid #707070;
	padding: 10px 16px;
	p {
		margin: 0;
		padding: 0 0 2px;
		font-size: rem-calc(12);
	}
}
