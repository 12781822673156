/**
 * MIXINS
 */

@mixin xy-center() {
	position:relative;
	top:50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/**
 * Position absolute
 */

.position-absolute {
	position: absolute;
}

/**
 * 
 */

.position-relative {
	position: relative;
}

/**
 * DISPLAY
 */

.hide { 
	display: none !important; 
}

/**
 * FLOAT
 */
.float {
	float: left;
}

/**
 * CLEAR
 */
.clear {
	clear: both;
}

/**
 * Cell
 */
.grid-padding-x > .cell-collapse {
	padding-right: 0;
    padding-left: 0;
}

/**
 * Background-colors
 */

.bgc-black {

	background-color: $black;

	h1,h2,h3,h4, 
	li {
		color: $white;
	}

	p, 
	.gform_confirmation_wrapper, 
	a {
		color: $font-gray;
	}
	
	//Supprisingly makes half right white 
	&.bgc-white-half-right {

		@include breakpoint(large) {
			position: relative;

			&:before {
				background-color: #fff;
				position: absolute;
				top: 0;
				right: 0;
				content: '';
				width: 50%;
				height: 100%;

			}
		}
	}

	//Put here to suggest it's only when parent has bgc-black
	.bgc-white {

		background-color: #fff;
		
		h1,h2,h3,h4, 
		li {
			color: $black;
		}

		p, 
		.gform_confirmation_wrapper {
			color: $font-gray;
		}
	}
}

/**
 * Background
 */

.bg {
	position: relative;
	background-repeat: no-repeat;

	&.bg-cover {
		background-size: cover;
    }

    &.bg-center {
    	background-position: center center;
    }
	
	&.bg-tint{	
		&:before {
			position: absolute;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			content: ' ';
			background: rgba(0,0,0,0.5);
		}
	}

	&.bg-tile-texture {	
		&:after {
			position: absolute;
			top: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			content: ' ';
			background-image: url(../img/tile-texture.png);
		}
	}

	&.bg-large-hide {
		@include breakpoint(large) {
			background: none !important;
			&:before, 
			&:after {
				display: none;
			}
		}
	}
	
	&.bg-height-100-percent {
		padding-top: 100%;
		@include breakpoint(large) {
			padding-top: 0;
			height: 649px;
		}
	}

	/**
	 * Bg Placeholders
	 */
	
	&.bg-square {
		height: 100%;
		height: 500px;
		background-color: #000;
	}
	
	&.bg-square-halves {
		height: 50%;
		height: 320px;
		background-color: #000 !important;
	}
	
	/**
	 * Pos Absolute
	 */
	&.position-absolute {
				
		display: none;
		
		@include breakpoint(large) {
			display: block;
			position: absolute;
			z-index: 100;
			width: 50%;
			height: 100%;
			top: 0;

			&.bg-right {
				right: 0;
			}
		}
	}
}

/**
 * Color
 */

.color {
	&-gray{
		color: $font-gray-dark;
	}
}

/**
 * Padding and Margins 
 */
$section-margin-small: rem-calc(40) 0 rem-calc(16);

$editorial-padding-small: rem-calc(40) rem-calc(16) rem-calc(16);

/**
 * 
 */

