/*  Main SCSS Files */

#wpadminbar {
  display: none;
}

@import '../../bower_components/font-awesome/scss/font-awesome.scss';
@import '../../bower_components/owl.carousel/src/scss/owl.carousel.scss';



.hide-for-small {
  width: 100%;
  height: 100%;

}



footer {
  width: 100%;
  background-color: #393939;
  padding: 20px 0;
  color: white;
  font-size: 13px;

  p {
    padding: 10px 0;
    margin: 0;
  }
}

#header-wrap {
  background-color: #e8e8e8;
  background-size: cover;
  background-position: center center;


  #header-overlay {

    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    padding: 40px 0;
  }

  header {
    color: white;
    height: 500px;

    ul#menu-main-1 {
      li {

        ul.submenu {

          li {
            a {
              padding-left: 20px;
            }
          }
        }

        &.nopad {
          a {
            padding-left: 0;
          }
        }

        &.is-dropdown-submenu-item {

          a {
            color: #000;
            font-size: 14px;
            line-height: 18px;



            &:hover {
              text-decoration: underline;
            }
          }
        }

        a {
          color: white;

          &:hover {
            color: #C99732;
          }




        }
      }
    }

    a {
      color: white;
    }

    #text-block {

      margin-top: 120px;
    }
  }
}

#desktop-menu {
  float: left;


}

.grey-wrap {
  background-color: #E8E8E8;
  padding: 40px 0;

  &.announcement-block {

    padding: 40px;
    margin: 40px 0;
    width: 100%;

    h3 {
      font-size: 20px;
      text-transform: uppercase;
      color: #C99732;

    }
  }
}

.theform {
  margin-top: 40px;
}

.home-boxes {
  margin-top: 40px;

}

.home-box {
  margin-bottom: 30px;
  height: 100%;
  position: relative;
  overflow: hidden;

  iframe {
    width: 100%;
    border: none;
  }

  img {
    width: 100%;
  }

  h3 {
    font-size: 20px;
    text-transform: uppercase;
    color: #C99732;

  }

  p {
    margin-top: 10px;
  }
}

.dropdown.menu>li.is-dropdown-submenu-parent {

  a {
    line-height: 0.9rem;
  }

  a:after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 1rem;
    left: 0.2em;
    position: relative;
    top: 0.4em;
    transform: rotate(45deg);
    vertical-align: top;
    width: 1em;
    border-color: #C99732;
    transition: transform .2s ease-in-out;
    transform-origin: 75% 25%;
  }

  &:hover {

    a:after {

      position: relative;

      transform: rotate(135deg);

    }

  }

}

.dropdown.menu {

  li.is-submenu-item {

    a:after {
      display: none !important;
    }
  }
}




@media screen and (min-width:1024px) {

  [type=button],
  [type=submit] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    background-color: #C99732;
    padding: 10px 30px;
    border: none;
    color: white;

    &:hover {
      background-color: black;
    }

  }
}

[type=color],
[type=date],
[type=datetime-local],
[type=datetime],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
textarea {
  border: 1px solid black;
}

.module {

  margin-top: 40px;

  a {
    color: #C99732;

    &:hover {
      color: #C99732;
      text-decoration: underline;
    }
  }
}

#presentations {
  margin-top: 40px;

  .presentation {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
  }

  ul {

    padding: 10px;


    li {
      padding: 10px 0;
      background-color: #E8E8E8;
      margin-bottom: 5px;
      list-style-type: none;

      a {
        color: black;
        margin-left: 20px;
        display: block;
        ;

        &:hover {
          text-decoration: underline;
        }
      }

    }

  }

  strong {
    font-size: 20px;
    text-transform: uppercase;
    color: #C99732;
  }
}

#advisors {
  margin: 40px 0;

  strong.type {
    font-size: 20px;
    text-transform: uppercase;
    color: #C99732;
  }


  .advisor {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
  }

}

#gallery {
  padding: 40px 0;

  .grid-x {
    margin-bottom: 20px;
  }
}

img.thumb-gallery {
  width: 100%;
  margin-bottom: 10px;
}

.images {

  h3 {

    font-size: 20px;
    text-transform: uppercase;
    color: #c99732;
  }

  h4 {
    font-size: 18px;
    margin-top: 10px;
  }
}

#all-projects {

  .block {
    margin-bottom: 40px;
  }

  margin: 40px 0;

  .menu {
    margin-bottom: 20px;

    li {

      &:first-child a {
        padding-left: 0px;
      }

      a {
        color: black;
        text-decoration: underline;

        &:hover {
          color: #C99732;
        }
      }
    }
  }
}

.leftblock {
  color: #C99732;
}

.boxlink p {
  color: black
}

.drilldown {

  &.is-drilldown-submenu {

    a {
      padding: 20px;
    }
  }

  li {


    a {
      padding: 20px;
      background: #222;
      color: white;
    }
  }
}

.drilldown.align-left .is-drilldown-submenu-parent>a:after,
.drilldown .is-drilldown-submenu-parent>a:after {
  border-color: transparent transparent transparent #C99732;
}

.drilldown .js-drilldown-back>a:before {
  border-color: transparent #C99732 transparent transparent;
}



.drilldown .is-drilldown-submenu a {
  padding: 20px;
}

.logoholder {
  height: 60px;
  padding: 10px;
}

.grid-container {
  width: 100%;
}

.footermenu,
.menu {

  li {
    list-style-type: none;

    a {
      color: white;

      &:hover {
        color: #C99732;
        text-decoration: underline;
      }
    }
  }
}

.footermenu {

  float: right !important;
}