// ************
// Font awesome
// ************

//@import 'font-awesome';

// **********
// Cookie Consent
// **********
@import 'vendor/cookie-consent';

// **********
// Owl Carousel
// **********

//@import 'vendor/owl/owl.carousel.scss';

// ****************
// Settings Prepend
// ****************

$font-gray: #9a9797;
$font-gray-dark: #5a5a5a;

// **********
// Foundation
// **********

@import '../../bower_components/foundation-sites/scss/foundation';
@import 'settings/settings';
@import 'foundation';

@include foundation-global-styles; // Always include the global-styles
@include foundation-grid;
@include foundation-xy-grid-classes;
//@include foundation-flex-grid;
@include foundation-flex-classes;

@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-menu;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;

// ****************
// Project Specific
// ****************

@import 'utils/utils';
@import 'global/site-sticky-footer';
@import 'section/section';

@import 'elements/form';
@import 'elements/buttons';
@import 'elements/icons';
@import 'elements/list';

@import 'typography/typography';

@import 'global/site-header';
@import 'global/site-footer';
