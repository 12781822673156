
section {
 	
 	/**
 	 * Section Content Load Transition
 	 */
 	
 	.transit {
		opacity: 0;
		//background: orange;
	}
	.transit-opacity-1s {
		transition: opacity 1s;			
	}
	.transit-opacity-1s-0_3s {
		transition: opacity 1s 0.3s;
	}
	.transit-opacity-1s-0_5s {
		transition: opacity 1s 0.5s;
	}

	ul.transit-list {
		li {
			opacity: 0;
			&:nth-child(1) { transition: opacity 1s 0.6s; }
			&:nth-child(2) { transition: opacity 1s 0.7s; }
			&:nth-child(3) { transition: opacity 1s 0.8s; }
			&:nth-child(4) { transition: opacity 1s 0.9s; }
			&:nth-child(5) { transition: opacity 1s 1s; }
		}
	}

 	/**
 	 * Section Default
 	 */
 	
	.list {
		padding: rem-calc(16) 0;
	}

	.editorial-modules {	
		.cell {	
			min-height: 300px;
			margin-bottom: 20px;
		}
	}

	/**
 	 * Section Specifics
 	 */
 	
	&.section-intro {		
	}

	&.section-lists {		
	}

	&.section-modules {			
	}

	&.section-form { 		
	}

	/**
	 * Loads objects in transition
	 */
	&.loaded {
		.transit, 
		.transit-list li {
			opacity: 1;
		}
	}
}

